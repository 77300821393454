<template>
<div>
          <b-col md="12" class="my-2">
            <custom-input
              v-if="variables"
              class="my-1"
              id="custom-input-r1"
              :possibleValues="variables"
              v-model="agent.register_1"
              advanced
            >
              <template #label>
                {{ $t(`agent.list.${i18nKey}.register_1.title`) }}
                <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
                <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_1.description`) }}</span>
              </template>
            </custom-input>
              <div v-else class="mb-2">
                <b-skeleton class="mb-25" width="25%" height="12px" />
                <b-skeleton class="mb-25" width="100%" height="37px" />
                <b-skeleton class="mb-25" width="25%" height="14px" />
              </div>
          </b-col>

          <!-- FOUND / NOT FOUND CARDS ============== -->
          <b-collapse :visible="!full">
            <b-row>
              <b-col class="d-flex mt-0 mb-2" md="12">
                <b-col md="6" class="d-flex justify-content-start mx-0">
                  <div class="w-100">
                    <div class="text-right">
                      {{ $t('common.terms.if').toUpperCase() }} <b>{{ $t(`agent.list.${i18nKey}.card_desc_not_null`) }}</b>
                    </div>
                    <div class="p-1 w-100 if-card-false text-left">
                      <!-- custom css class (see 'styles' below) -->
                      <b-icon icon="exclamation-triangle" scale="1.7" class="mx-1"></b-icon>
                      {{ $t('common.terms.card_error') }}
                    </div>
                  </div>
                  <div>
                    <b-icon icon="arrow-return-left" scale="2" class="mx-1 mt-3"></b-icon>
                  </div>
                </b-col>
                <!-- middle ====================-->
                <b-col md="6" class="d-flex justify-content-end mx-0">
                  <div>
                    <b-icon icon="arrow-return-right" scale="2" class="mx-1 mt-3"></b-icon>
                    
                  </div>
                  <div class="w-100">
                    <div class="text-left">
                      {{ $t('common.terms.if').toUpperCase() }} <b>{{ $t(`agent.list.${i18nKey}.card_desc_null`) }}</b>
                    </div>
                    <div class="p-1 w-100 if-card-true text-right ">
                      <!-- custom css class (see 'styles' below) -->
                      <b-icon icon="play" scale="2" class="mx-1"></b-icon>
                      {{ $t('common.terms.card_continue') }}
                    </div>
                  </div>
                </b-col>
              </b-col>
            </b-row>
          </b-collapse>
          <!-- ================ -->
          <!-- version with destiny: -->
          <b-collapse :visible="full">
            <b-row>
              <b-col md="12" class="d-flex justify-content-center">
                <span class="text-center"><b>{{$t("common.terms.store_result")}}</b></span>
              </b-col>
              <b-col md="12" class="d-flex justify-content-center mt-1">
                <b-icon icon="arrow-down-short" scale="2" class="mx-1" variant="success"></b-icon>
              </b-col>
            </b-row>
          </b-collapse>
          <!-- ================ -->

          <b-collapse :visible="full">
            <b-col md="12" class="my-2">
              <custom-input
                v-if="variables"
                class="my-1"
                id="custom-input-rd"
                :possibleValues="variables"
                v-model="agent.register_destiny"
                advanced
              >
                <template #label>
                  {{ $t(`agent.list.${i18nKey}.register_destiny.title`) }}
                  <helper-tooltip tooltipPlacement="right" size="12" innerText="eopae" innter />
                  <span class="float-right text-right mx-25 text-secondary">{{ $t(`agent.list.${i18nKey}.register_destiny.description`) }}</span>
                </template>
              </custom-input>
                <div v-else class="mb-2">
                  <b-skeleton class="mb-25" width="25%" height="12px" />
                  <b-skeleton class="mb-25" width="100%" height="37px" />
                  <b-skeleton class="mb-25" width="25%" height="14px" />
                </div>
            </b-col>
          </b-collapse>

          <div align="right">
            <b-button variant="outline-purple" @click="full = !full" class="mt-2 mx-2 danger">
              <span v-if="full">{{ $t('common.terms.remove_destiny') }}</span>
              <span v-else>{{ $t('common.terms.add_destiny') }}</span>
            </b-button>
          </div>
</div>
</template>

<script>
import { BLink, BSkeleton, BFormCheckbox, BButton, BContainer, BCol, BRow, BSidebar, BIcon, BCollapse } from "bootstrap-vue";
import { mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DefaultAgent from "./../DefaultAgent"
import VariablesPanel from "@/views/pages/middleware/MiddlewareManagement/Components/VariablesPanel.vue";
import CustomInput from "@/views/pages/middleware/MiddlewareManagement/Components/CustomInput.vue";
import NormalizedAgent from "@/custom/class/NormalizedAgent.js";
import HelperTooltip from '@/layouts/components/HelperTooltip';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

import AgentsMixin from '../AgentsMixin'

export default {
  mixins: [AgentsMixin],

  components: {
    BButton,
    BContainer,
    BCol,
    BRow,
    BSidebar,
    VariablesPanel,
    CustomInput,
    BFormCheckbox,
    BIcon,
    BSkeleton,
    HelperTooltip,
    BLink,
    VuePerfectScrollbar,
    BCollapse,
  },
  props: {
    middlewareID: {
      type: Number,
      required: true,
    },
    agentID: {
      type: Number,
      default: undefined,
    },
    value: {
      type: NormalizedAgent,
      default: undefined
    },
    uuid: {
      type: String,
      default: uuidv4(),
    },
    width: {
      type: String,
      default: "40%"
    },
    agentBlockID: {
      type: [ Number, String ],
      default: 1,
    },
    variables:{
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      actionID: 41,
      i18nKey: 'is_null',
      defaultAgent: {
        id: null,
        enum_agent_action_id: this.actionID,
        agent_block_id: this.agentBlockID,
        register_1: {
          source: 7,
          value: ""
        },
        register_2: {},
        register_destiny: {
          source: 10,
          value: ""
        },
        execution_order: 1,
        fatal_on_fail: true,
      },

      uuidMap: {},

      full: false,
      variant_map: null,
      isSaving: false,
    };
  },
  computed: {
    agent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    toSaveRegs() {
      if (this.full){
        return{
          register_1: true,
          register_2: false,
          register_destiny: true,
          } 
        }else {
          return {
            register_1: true,
            register_2: false,
            register_destiny: false,
          }
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["insertAgent", "updateAgent", "getAgentByID"]),
    init() {
      DefaultAgent.fetchAgentByID(this)
    },
    save() {
      return DefaultAgent.save(this)
    },
    validate() {
      return DefaultAgent.validate(this)
    },
    getID(key) {
      if (this.uuidMap[key]) {
        return this.uuidMap[key];
      }

      const uuid = uuidv4();
      this.uuidMap[key] = uuid;

      return uuid;
    },
    toggleSidebar() {
      this.$root.$emit("bv::toggle::collapse", this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>

.sidebar-container{
  max-height: 97vh;
  overflow-y: auto !important;
}

.sidebar-fixed-header{
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.sidebar-content{
  position: relative !important;
  padding-bottom: 20px;
  margin-bottom: 70px !important;
}


.if-card-true{
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid #28c76f;
}
.if-card-false{
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid #ea5455;
}
</style>